(function ($) {

//obsluha uživatelského menu
$('.user-menu-submit, .w-user-menu-submit, .w-notifications-menu-back').on('click', function (e) {
	e.preventDefault();
	e.stopPropagation();
	handleUserMenu('toggle');
});

//zavření mobilní verze uživatelského menu
$('.user-menu-close, .w-user-menu-close').on('click', function (e) {
	e.preventDefault();
	e.stopPropagation();
	handleUserMenu('off');
});

// Ovladač submenu v user menu
$('.user-menu-submenu-trigger, .w-user-menu-submenu-trigger').on('click', function(e) {
	e.preventDefault();
	e.stopImmediatePropagation();
	e.stopPropagation();


	var submenuContentElementName = '.user-menu-submenu-content';
	if (e.target.className.indexOf('w-') !== -1) {
		submenuContentElementName = '.w-user-menu-submenu-content';
	}

	var userMenuItemParent = $(this).closest('li');
	var userMenuItemContent = userMenuItemParent.find(submenuContentElementName);


	if (!userMenuItemContent.is(':animated')) {

		//ostatní submenu
		$(submenuContentElementName).not(userMenuItemContent).animate({ height: 0, opacity: 0 }, 150, function() {
			$(this).closest('li').removeClass('open-content');
			$(this).css({'height': '','opacity': '','display': ''});
		}).closest('li').removeClass('open-trigger');

		//aktuální submenu
		userMenuItemParent.toggleClass('open-trigger');
		userMenuItemContent.animate({ height: 'toggle', opacity: 'toggle' }, 150, function() {
			$(this).css({'height': '','opacity': '','display': ''});
			userMenuItemParent.toggleClass('open-content');
		});

	}

});

// Zavření usermenu při kliku jinde než v usermenu
$(document).on("click", (function(e) {

	var filter = $('.w-notifications-menu, .user-menu, .w-user-menu');
		if(!$(e.target).closest(filter).length && !$(e.target).is(filter)) {
			handleUserMenu('off');
		}
	})
);

// Zavření usermenu stisknutím klávesy "Esc"
$(document).keyup(function (e) {
	if (e.keyCode == 27) {
		handleUserMenu('off');
	}
});

// Obsluha uživatelského menu

function handleUserMenu(actionUserMenu) {
	var portalRoot = $('.portal');

	if (portalRoot.hasClass('active-user-menu') || actionUserMenu == 'off') {
		portalRoot.removeClass('active-user-menu');
		portalRoot.removeClass('active-notifications-menu');
		portalRoot.removeClass('active-ios-overlay');
        $('.w-user-menu-level-1').removeClass('state-hidden');
        $('.w-user-menu-level-2').addClass('state-hidden');
	} else {
		portalRoot.removeClass('active-notifications-menu');
		portalRoot.addClass('active-user-menu');
	}
}


$('.w-user-menu-badge').on('click', function (e) {
	e.preventDefault();
	e.stopPropagation();

	$('.w-user-menu-level-2').removeClass('state-hidden');
	$('.w-user-menu-level-1').addClass('state-hidden');

});

$('.w-user-menu-back').on('click', function (e) {
	e.preventDefault();
	e.stopPropagation();

	$('.w-user-menu-level-1').removeClass('state-hidden');
	$('.w-user-menu-level-2').addClass('state-hidden');

});

})(jQuery);